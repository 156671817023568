import React, { useContext, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { object, string } from 'yup'
import { useFormValidation } from '../hooks/useFormValidation'
import { UserProperty } from '../types/userProperty'
import { useUserProperty } from '../hooks/rest/useUserProperty'
import { TextFormGroup } from './formGroups/TextFormGroup'
import { I18nContext } from '../providers/I18nProvider'

const TEXT_MIN = 25
const TEXT_MAX = 2000

interface FormData {
  email: string
  text: string
}
interface SupportMessageFormProps {
  initial: FormData
  onSubmit: (formData: Record<string, any>) => void
}

export const SupportMessageForm: React.FC<SupportMessageFormProps> = ({ initial, onSubmit }) => {
  const { translate } = useContext(I18nContext)
  const { property: email } = useUserProperty(UserProperty.EMAIL)
  const { formData, setFormData, validate, validateAt, filterErrors } = useFormValidation<FormData>({
    initial,
    schema: object().shape({
      email: string()
        .transform((_, value) => (value === '' ? undefined : value))
        .email('invalid')
        .required('required'),
      text: string()
        .transform((_, value) => (value === '' ? undefined : value))
        .min(TEXT_MIN, 'min')
        .max(TEXT_MAX, 'max')
        .required('required')
    })
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    validate(() => onSubmit(formData))
  }

  useEffect(() => {
    if (!!email) {
      setFormData({ ...formData, email })
    }
  }, [email])

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TextFormGroup
        formId="support"
        name="email"
        autoComplete="email"
        type="email"
        required={true}
        withTrimSpaces
        max={0}
        value={formData?.email || ''}
        onChange={email => setFormData({ ...formData, email: email })}
        onBlur={() => validateAt('email')}
        errors={filterErrors('email')}
      />

      <TextFormGroup
        formId="support"
        name="text"
        min={TEXT_MIN}
        max={TEXT_MAX}
        autoComplete="off"
        required={true}
        withTrimSpaces
        as="textarea"
        errors={filterErrors('text')}
        value={formData?.text || ''}
        onChange={text => setFormData({ ...formData, text: text })}
      />

      <Button type="submit" variant="primary" className="w-100 my-2">
        {translate('support.submit')}
      </Button>
    </Form>
  )
}
