import React, { Fragment, useContext } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { ReactComponent as Globe2 } from 'bootstrap-icons/icons/globe2.svg'
import { I18nContext } from '../providers/I18nProvider'
import { Language } from '../types/language'

export const I18nLanguageSelect: React.FC = () => {
  const { translate, lang, setLang } = useContext(I18nContext)

  const EnglishFlag = () => (
    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="20" height="20">
      <clipPath id="s">
        <path d="M0,0 v30 h60 v-30 z" />
      </clipPath>
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <g clipPath="url(#s)">
        <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4" />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
      </g>
    </svg>
  )

  const RussianFlag = () => (
    <svg
      className="me-2"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="20"
      height="20"
      viewBox="0 0 22 14.667"
      enableBackground="new 0 0 22 14.667"
    >
      <g id="Page_1">
        <g id="Слой_1_1_">
          <polygon fill="#FFFFFF" points="0,0.001 22,0.001 22,7.334 0,7.334 0,0.001" />
          <polygon fill="#FF0000" points="0,7.334 22,7.334 22,14.667 0,14.667 0,7.334" />
          <polygon fill="#0000FF" points="0,4.89 22,4.89 22,9.778 0,9.778 0,4.89" />
          <polygon
            fill="none"
            stroke="#000000"
            strokeWidth="0.25"
            strokeMiterlimit="2.4142"
            points="0,0.001 0,4.89 0,7.334 0,9.778 0,14.667 22,14.667 22,9.778 22,4.89 22,0.001 0,0.001"
          />
        </g>
      </g>
    </svg>
  )

  return (
    <NavDropdown
      title={
        <Fragment>
          <Globe2 width="20" height="20" className="me-2" />
          {translate(`header.languages.${lang}`)}
        </Fragment>
      }
    >
      <NavDropdown.Item onClick={() => setLang(Language.EN)}>
        <EnglishFlag />
        {translate('header.languages.en')}
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => setLang(Language.RU)}>
        <RussianFlag />
        {translate('header.languages.ru')}
      </NavDropdown.Item>
    </NavDropdown>
  )
}
