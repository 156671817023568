export enum UserProperty {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number'
}

export type UserPropertyResponse = {
  value: string
  name: string
  userId: number
}
