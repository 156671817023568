export enum FileType {
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_USAGE = 'terms_of_usage',
  PAYMENTS = 'payments'
}

export const downloadFileLinkTitle = {
  [FileType.PRIVACY_POLICY]: 'documentType.privacyPolicy',
  [FileType.PAYMENTS]: 'documentType.paymentPolicy',
  [FileType.TERMS_OF_USAGE]: 'documentType.termsOfUsage'
}
