import { useEffect, useState } from 'react'
import { authAxios } from '../../util/api'
import { AppConfig, DEFAULT_APP_CONFIG } from '../../types/appConfig'

export const useAppConfig = () => {
  const [appConfig, setAppConfig] = useState<AppConfig>(DEFAULT_APP_CONFIG)

  useEffect(() => {
    authAxios()
      .get<AppConfig>('/api/config')
      .then(({ data }) => setAppConfig(data))
  }, [])

  return { appConfig }
}
