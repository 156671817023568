import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Welcome } from './components/Welcome'
import './styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import { LoadingSpinner } from '@adverton/common'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter>
    <Suspense fallback={<LoadingSpinner />}>
      <Welcome />
    </Suspense>
  </BrowserRouter>
)
