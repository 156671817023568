import { I18nContext, Page, authAxios } from '@adverton/common'
import { Content } from './Content'
import { Logo } from './Logo'
import { useContext, useEffect, useState } from 'react'

export const WrappedWelcomePage = () => {
  const [translate, setTranslate] = useState(null)
  const { lang } = useContext(I18nContext)

  useEffect(() => {
    authAxios()
      .get(`/api/i18n/welcome?lang=${lang}`)
      .then(({ data }) => setTranslate(data.i18n.welcome))
  }, [lang])

  return (
    <Page show={!!translate} containerClasses="adv-bg" header={Logo}>
      <Content translate={translate} />
    </Page>
  )
}
