import React, { Component, Fragment, ReactNode } from 'react'
import { Button } from 'react-bootstrap'

interface ErrorBoundaryProps {
  errorPage?: ReactNode
  children?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  showMore: boolean
  error?: Error
  errorInfo?: React.ErrorInfo
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, showMore: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState(prevState => ({ ...prevState, error, errorInfo }))
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          {this.props.errorPage ?? (
            <Fragment>
              <div className="min-vh-100 d-flex justify-content-center">
                <div className="align-self-center">
                  <h1>Something went wrong.</h1>
                </div>
              </div>
            </Fragment>
          )}
          <div>
            <Button
              variant="link"
              className="px-0"
              onClick={() => this.setState(p => ({ ...p, showMore: !p.showMore }))}
            >
              {this.state.showMore ? 'show less' : 'show more'}
            </Button>
            {this.state.error && (
              <div className={this.state.showMore ? 'd-grid' : 'd-none'}>
                <h2 className="text-danger">Error: {this.state.error.message}</h2>
                <pre className="text-danger">{this.state.error?.stack}</pre>
                <pre className="text-danger">{this.state.errorInfo?.componentStack}</pre>
              </div>
            )}
          </div>
        </Fragment>
      )
    }

    return this.props.children
  }
}
