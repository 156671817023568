import React, { useContext, useEffect, useId, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { goTo } from '../util/goTo'
import { I18nContext } from '../providers/I18nProvider'
import { ModalDialog } from './ModalDialog'
import { CookieNames } from '../types/cookieNames'
import { AuthContext } from '../providers/AuthProvider'
import { AppConfigContext } from '../providers/AppConfigProvider'

type Props = {
  show?: boolean
  onClose?: () => void
}

export const PhoneRequestDialog = ({ show = true, onClose = () => {} }: Props) => {
  const navigate = useNavigate()
  const {
    userProperties: { phoneNumber, telegramEnabled }
  } = useContext(AuthContext)
  const { translate } = useContext(I18nContext)
  const { whatsappNotificationEnabled, userTelegramEnabled } = useContext(AppConfigContext)
  const [showModal, setShowModal] = useState(false)
  const id = useId()

  useEffect(() => {
    if (show) {
      if (
        phoneNumber ||
        telegramEnabled ||
        document.cookie.includes(`${CookieNames.NEVER_ASK_PHONE_NUMBER}=true`) ||
        (!whatsappNotificationEnabled && !userTelegramEnabled)
      ) {
        onClose()
      } else {
        setShowModal(true)
      }
    }
  }, [show])

  useEffect(() => {
    document.cookie = `${CookieNames.ASK_PHONE_NUMBER}=false;path=/`
  }, [])

  const isAdvModule = window.location.pathname.startsWith('/adv')

  return (
    <ModalDialog
      show={showModal}
      titleText={translate('addPhoneNumber.title')}
      continueText={translate('addPhoneNumber.continue')}
      onSubmit={() => {
        setShowModal(false)
        isAdvModule ? navigate('/settings') : goTo({ pathname: '/adv/settings' })
      }}
      onClose={() => {
        setShowModal(false)
        onClose()
      }}
    >
      <p>{translate('addPhoneNumber.text')}</p>
      <Form.Check
        id={id}
        type="checkbox"
        label={translate('addPhoneNumber.doNotShowAgain')}
        onChange={({ target: { checked } }) => {
          document.cookie = `${CookieNames.NEVER_ASK_PHONE_NUMBER}=${checked};path=/;max-age=${Number.MAX_SAFE_INTEGER}; SameSite=Lax`
        }}
      />
    </ModalDialog>
  )
}
