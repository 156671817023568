import SockJS from 'sockjs-client'
import Stomp from 'stompjs'

let socket = null
let stompClient = null

export const connect = (accessToken, onConnected, onDisconnected) => {
  socket = new SockJS(`/websocket?token=${accessToken}`)
  stompClient = Stomp.over(socket)

  stompClient.debug = message => {
    console.debug(message)
  }

  stompClient.connect({}, onConnected, onDisconnected)
}

export const disconnect = onDisconnected => {
  if (stompClient) {
    stompClient.disconnect(onDisconnected)
  }
}

export const subscribe = (...args) => {
  if (socket.readyState !== SockJS.CONNECTING && stompClient) {
    const { unsubscribe } = stompClient.subscribe(...args)
    console.debug(`Web Socket subscribed: ${args[0]}`)
    return unsubscribe
  } else {
    console.warn(`Cannot subscribe. Web Socket is not ready. readyState = ${socket.readyState}`)
    return () => {}
  }
}

export const send = (...args) => {
  if (stompClient) {
    stompClient.send(...args)
  }
}
