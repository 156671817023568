import { LoadingSpinner } from '@adverton/common'
import axios from 'axios'
import { Content } from './Content'
import { Logo } from './Logo'
import { useEffect, useState } from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap'

export const WelcomePage = () => {
  const [translate, setTranslate] = useState(null)

  useEffect(() => {
    axios.get('/api/i18n/welcome').then(({ data }) => setTranslate(data.i18n.welcome))
  }, [])

  return translate ? (
    <Row className="w-100 m-0 p-0 min-vh-100">
      <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-end adv-z-index-1046" />
      <Col sm={12} md={8} lg={6} xl={6} xxl={4} className="p-0">
        <div className={'min-vh-100 d-flex flex-column justify-content-between'}>
          <header className="position-fixed start-0 top-0 w-100 adv-bg adv-z-index-6">
            <Row className="w-100 m-0 p-0">
              <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-end" />
              <Col sm={12} md={8} lg={6} xl={6} xxl={4} className="p-0">
                <Navbar expand={false} className="adv-bg">
                  <Container fluid>
                    <h2 className="my-0">
                      <Logo />
                    </h2>
                  </Container>
                </Navbar>
              </Col>
              <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white" />
            </Row>
          </header>

          <main role="main" className={'flex-fill px-0 m-0 w-100 adv-bg'}>
            <div className="p-3">
              <Content translate={translate} />
            </div>
          </main>
        </div>
      </Col>
      <Col sm={0} md={2} lg={3} xl={3} xxl={4} className="p-0 bg-white border-start adv-z-index-1046" />
    </Row>
  ) : (
    <LoadingSpinner />
  )
}
