import { useContext, useEffect, useState } from 'react'
import { RestErrorHandlingContext } from '../../providers/RestErrorHandlingProvider'
import { UserProperty, UserPropertyResponse } from '../../types/userProperty'

export const useUserProperty = (propertyName: UserProperty, skipFetch = false) => {
  const { axios } = useContext(RestErrorHandlingContext)
  const [property, setProperty] = useState('')

  useEffect(() => {
    if (!skipFetch) {
      axios.get<UserPropertyResponse>(`/api/userproperty/${propertyName}`).then(({ data }) => setProperty(data.value))
    }
  }, [propertyName, skipFetch])

  const save = (value: string) => axios.post(`/api/userproperty/${propertyName}`, { value })

  const saveWithVerification = (value: string, verificationCode: string) =>
    axios.post(`/api/userproperty/${propertyName}`, { value, verificationCode }).then(({ data }) => data)

  const sendVerificationCode = (value: string) => axios.post(`/api/userproperty/${propertyName}/verify`, { value })

  const deleteProperty = () => axios.delete(`/api/userproperty`, { params: { propertyName } })

  return { property, save, saveWithVerification, sendVerificationCode, deleteProperty }
}
