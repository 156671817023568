import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import { I18nContext } from '../providers/I18nProvider'
import { downloadFileLinkTitle, FileType } from '../types/fileType'

interface DocumentLinkProps {
  className?: string
  documentType: FileType
}

export const DocumentLink: React.FC<DocumentLinkProps> = ({ className = 'm-0 my-2 p-0 ', documentType }) => {
  const { translate } = useContext(I18nContext)

  return (
    <Button variant="link" className={className} href={`/static/${documentType}.pdf`} as="a" target="_blank">
      {translate(downloadFileLinkTitle[documentType])}
    </Button>
  )
}

export default DocumentLink
