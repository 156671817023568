import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { useSupportMessageSender } from '../hooks/rest/useSupportMessageSender'
import { SupportMessageForm } from '../form/SupportMessageForm'
import { I18nContext } from '../providers/I18nProvider'
import { AppConfigContext } from '../providers/AppConfigProvider'
import DocumentLink from './DocumentLink'
import { FileType } from '../types/fileType'

interface SupportMessageDialogProps {
  show?: boolean
  onClose: () => void
}

export const SupportMessageDialog: React.FC<SupportMessageDialogProps> = ({ show = false, onClose }) => {
  const { translate } = useContext(I18nContext)
  const { send } = useSupportMessageSender()
  const { version } = useContext(AppConfigContext)

  return (
    <Modal show={show} centered className="px-sm-3">
      <Modal.Header closeButton onHide={() => onClose()}>
        <Modal.Title>{translate('support.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SupportMessageForm
          initial={{ email: '', text: '' }}
          onSubmit={({ email, text }) => send(email, text, () => onClose())}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {version}
        <div className="d-flex flex-column align-items-start">
          <DocumentLink documentType={FileType.PRIVACY_POLICY} />
          <DocumentLink documentType={FileType.TERMS_OF_USAGE} />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default SupportMessageDialog
