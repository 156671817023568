import React from 'react'

export const SupportIcon = ({ width = 16, height = 16, className = '' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 8C5 8.55228 4.55228 9 4 9C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7C4.55228 7 5 7.44772 5 8Z"
      fill="black"
    />
    <path
      d="M9 8C9 8.55228 8.55229 9 8 9C7.44772 9 7 8.55228 7 8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8Z"
      fill="black"
    />
    <path
      d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill="black"
    />
    <path
      d="M2.16466 15.8029L2.18489 15.7989C4.01434 15.4363 5.13337 14.9571 5.65284 14.6939C6.39508 14.8929 7.18324 15 8 15C12.4183 15 16 11.866 16 8C16 4.13401 12.4183 1 8 1C3.58172 1 0 4.13401 0 8C0 9.76087 0.743061 11.3699 1.96979 12.6001C1.89596 13.3711 1.69422 14.1984 1.44648 14.9181L1.44259 14.9294C1.41245 15.0167 1.38164 15.1023 1.3503 15.1861C1.30097 15.3179 1.25034 15.4451 1.19898 15.5664C1.12037 15.7521 1.27271 15.9603 1.47172 15.9277C1.61264 15.9047 1.75 15.8808 1.88382 15.8563C1.97922 15.8388 2.07283 15.821 2.16466 15.8029ZM2.96523 12.6954C2.99363 12.3988 2.88828 12.1049 2.67789 11.894C1.6173 10.8304 1 9.46809 1 8C1 4.80754 4.0044 2 8 2C11.9956 2 15 4.80754 15 8C15 11.1925 11.9956 14 8 14C7.27076 14 6.56966 13.9044 5.91182 13.728C5.67381 13.6642 5.42062 13.6905 5.20082 13.8019C4.81358 13.9981 3.96166 14.3721 2.56677 14.6945C2.75502 14.0519 2.90159 13.3601 2.96523 12.6954Z"
      fill="black"
    />
  </svg>
)
