import React, { createContext } from 'react'
import { useAppConfig } from '../hooks/rest/useAppConfig'
import { AppConfig, DEFAULT_APP_CONFIG } from '../types/appConfig'

export const AppConfigContext = createContext<AppConfig>(DEFAULT_APP_CONFIG)
AppConfigContext.displayName = 'AppConfigContext'

export const AppConfigProvider = ({ children }) => {
  const { appConfig } = useAppConfig()

  return <AppConfigContext.Provider value={{ ...appConfig }}>{children}</AppConfigContext.Provider>
}
