export const selectPluralForm = ({ language, count, word: { singular, plural1, plural2 } }) => {
  if (language === 'ru') {
    /**
     * singular:
     *  1,21,31,41,51,61,71,81,91,101...'сообщение' (оканчиваются на 1 кроме оканчивающихся на 11)
     * plural1:
     *  2,3,4,22,23,24...'сообщения' (оканчиваются на 2,3,4 кроме оканчивающихся на 12, 13, 14)
     * plural2:
     *  5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,25...'сообщений' (оканчиваются на 5,6,7,8,9,0  + те, которые оканчиваются на 11, 12, 13, 14)
     */

    const lastTwoDigits = Number(String(count).slice(-2))

    if ([11, 12, 13, 14].includes(lastTwoDigits)) {
      return plural2
    }

    const lastDigit = Number(String(count).slice(-1))

    if ([2, 3, 4].includes(lastDigit)) {
      return plural1
    }

    if (lastDigit === 1) {
      return singular
    }

    return plural2
  } else {
    // en etc.
    if (count > 1) {
      return plural1
    } else {
      return singular
    }
  }
}
