import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { RestErrorHandlingContext } from '../../providers/RestErrorHandlingProvider'

export const useSupportMessageSender = () => {
  const { axios } = useContext(RestErrorHandlingContext)
  const location = useLocation()

  const send = (email, text, callback = () => {}) => {
    axios
      .post('/api/support', {
        userEmail: email,
        messageText: text,
        url: location.pathname
      })
      .then(() => callback())
  }

  return { send }
}
