import React, { createContext, useContext } from 'react'
import { Button, ToastContainer } from 'react-bootstrap'
import { useNotifications } from '../hooks/webSockets/useNotifications'
import { Notification } from '../components/Notification'
import { I18nContext } from './I18nProvider'

export const NotificationContext = createContext<{
  unreadNotificationsCount: number
  decrementUnreadUCount: () => void
}>({
  unreadNotificationsCount: 0,
  decrementUnreadUCount: () => {}
})
NotificationContext.displayName = 'NotificationContext'

export const NotificationProvider = ({ children }) => {
  const { translate } = useContext(I18nContext)
  const { notifications, setNotifications, unreadNotificationsCount, decrementUnreadUCount } = useNotifications()

  const showCloseAll = notifications.length > 1
  return (
    <NotificationContext.Provider value={{ unreadNotificationsCount, decrementUnreadUCount }}>
      {children}
      <ToastContainer
        className={`d-flex flex-column align-items-end position-fixed top-0 ${
          showCloseAll ? 'pt-4' : 'adv-pt-4rem'
        } pe-2 pb-4 w-100 vh-100 overflow-auto`}
        style={{ zIndex: 999 }}
      >
        <Button
          variant="link"
          className="pe-auto text-dark fw-semibold lh-lg pb-0 pt-2 border-0"
          onClick={() => setNotifications([])}
          hidden={!showCloseAll}
        >
          {translate('notifications.closeAll')}
        </Button>
        {notifications?.map((notification, index) => (
          <Notification
            key={notification?.notificationId || notification.text}
            notification={notification}
            onClose={() => setNotifications(notifications.filter((_, i) => i !== index))}
            className="adv-max-vw-95"
          />
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  )
}
