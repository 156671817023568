import { useEffect, useRef } from 'react'

export const useBrowserNavigationWrapper = (goBack?: (onCancel?: () => void) => void) => {
  const ref = useRef(goBack)

  useEffect(() => {
    ref.current = goBack
  }, [goBack])

  const disableBrowserBackClick = () => {
    //imitate disabling browser "back" button by addition current route as the previous one
    window.history.pushState(window.history.state, null, window.location.href)
  }

  useEffect(() => {
    if (goBack) disableBrowserBackClick()
    let loading = false

    const onPopState = () => {
      if (ref?.current) {
        disableBrowserBackClick()

        if (!loading) {
          loading = true
          ref.current(() => {
            loading = false
          })
        }
      }
    }

    window.addEventListener('popstate', onPopState) //fired after browser back completed
    return () => window.removeEventListener('popstate', onPopState)
  }, [])
}
