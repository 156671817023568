import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Spinner, Toast } from 'react-bootstrap'
import { ReactComponent as ExclamationSquareFill } from 'bootstrap-icons/icons/exclamation-square-fill.svg'
import { ReactComponent as InfoSquareFill } from 'bootstrap-icons/icons/info-square-fill.svg'
import { I18nContext } from '../providers/I18nProvider'
import { NotificationContext } from '../providers/NotificationProvider'
import { send } from '../util/socketClient'
import { Notification as _Notification } from '../types/notification'

interface NotificationProps {
  notification: _Notification
  doDecrement?: boolean
  onClose?: () => void
  onDelete?: (id: _Notification['notificationId']) => Promise<void>
  className?: string
}

export const Notification: React.FC<NotificationProps> = ({
  notification,
  doDecrement = false,
  onClose,
  onDelete,
  className = ''
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const observer = useRef<IntersectionObserver | null>(null)
  const { translate, translatePlural } = useContext(I18nContext)
  const { decrementUnreadUCount } = useContext(NotificationContext)
  const [localNotification, setLocalNotification] = useState(notification)
  const [deleteProcessing, setDeleteProcessing] = useState(false)

  useEffect(() => {
    const currentElement = ref.current
    observer.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (localNotification?.notificationId && entry.isIntersecting && !localNotification?.read) {
          setTimeout(() => {
            send('/adv/notification/markAsRead', {}, localNotification?.notificationId)
            if (doDecrement) {
              decrementUnreadUCount()
            }
            setLocalNotification(prev => ({ ...prev, read: true }))
          }, 1000)
        }
      })
    })

    observer.current.observe(currentElement)

    return () => {
      observer.current.unobserve(currentElement)
    }
  }, [ref, localNotification?.read])

  const getTimeAgo = createdOn => {
    if (!createdOn) {
      return ''
    }

    const createdDate = new Date(createdOn)
    const now = new Date()
    const elapsedMillis = now.getTime() - createdDate.getTime()
    const elapsedSeconds = Math.floor(elapsedMillis / 1000)
    const elapsedMinutes = Math.floor(elapsedSeconds / 60)
    const elapsedHours = Math.floor(elapsedMinutes / 60)
    const elapsedDays = Math.floor(elapsedHours / 24)
    const elapsedWeeks = Math.floor(elapsedDays / 7)

    if (elapsedWeeks >= 1) {
      return `${elapsedWeeks} ${translatePlural(elapsedWeeks, 'week')} ${translate('notifications.notification.ago')}`
    } else if (elapsedDays >= 1) {
      return `${elapsedDays} ${translatePlural(elapsedDays, 'day')} ${translate('notifications.notification.ago')}`
    } else if (elapsedHours >= 1) {
      return `${elapsedHours} ${translatePlural(elapsedHours, 'hour')} ${translate('notifications.notification.ago')}`
    } else if (elapsedMinutes >= 1) {
      return `${elapsedMinutes} ${translatePlural(elapsedMinutes, 'minute')} ${translate(
        'notifications.notification.ago'
      )}`
    } else {
      return translate('notifications.notification.justNow')
    }
  }

  return (
    <Toast ref={ref} className={className} bg="light" show={!!localNotification} onClose={onClose}>
      <Toast.Header closeButton={!!onClose}>
        {localNotification?.status === 'error' ? (
          <ExclamationSquareFill width="20" height="20" className="me-2" fill="red" />
        ) : localNotification?.status === 'warning' ? (
          <ExclamationSquareFill width="20" height="20" className="me-2" fill="orange" />
        ) : (
          <InfoSquareFill width="20" height="20" className="me-2" fill="#198754" />
        )}
        <strong className="me-auto">
          {localNotification?.title
            ? translate(localNotification?.title)
            : translate('notifications.notification.notificationTitle')}
        </strong>
        <small className="text-muted">{getTimeAgo(localNotification?.createdOn)}</small>
      </Toast.Header>
      <Toast.Body
        dangerouslySetInnerHTML={{ __html: translate(localNotification?.text, localNotification?.replacements) }}
      />
      {onDelete && (
        <div className="d-flex justify-content-end px-2 pb-2">
          <Button
            variant="link"
            size={'sm'}
            onClick={() => {
              setDeleteProcessing(true)
              onDelete(notification.notificationId).finally(() => setDeleteProcessing(false))
            }}
            className="text-decoration-none text-danger"
            disabled={deleteProcessing}
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              hidden={!deleteProcessing}
            />{' '}
            {translate('notifications.notification.delete')}
          </Button>
        </div>
      )}
    </Toast>
  )
}
