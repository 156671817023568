import React, { createContext } from 'react'
import { useSubscription } from '../hooks/rest/useSubscription'
import { UserSubscription } from '../types/userSubscription'
import { Subscription } from '../types/subscription'

export const SubscriptionContext = createContext<{
  subscriptions: Subscription[]
  currentSubscription: UserSubscription | undefined
  subscriptionsAvailableForUpgrade: Subscription[]
  creationAllowed: boolean
  refreshActive: (callback?: () => void) => void
  refreshCreationAllowed: (callback?: (creationAllowed: boolean) => void) => void
}>({
  subscriptions: [],
  currentSubscription: undefined,
  subscriptionsAvailableForUpgrade: [],
  creationAllowed: false,
  refreshActive: () => {},
  refreshCreationAllowed: () => {}
})
SubscriptionContext.displayName = 'SubscriptionContext'

export const SubscriptionProvider = ({ children }) => {
  const {
    subscriptions,
    currentSubscription,
    subscriptionsAvailableForUpgrade,
    creationAllowed,
    refreshActive,
    refreshCreationAllowed
  } = useSubscription()

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions,
        currentSubscription,
        subscriptionsAvailableForUpgrade,
        creationAllowed,
        refreshActive,
        refreshCreationAllowed
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}
