import React, { createContext, Fragment, useEffect, useState } from 'react'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { selectPluralForm } from '../util/selectPluralForm'
import { authAxios } from '../util/api'

const cache = {}

export const I18nContext = createContext<{
  translate: (path: string, replacements?: {}) => string
  translatePlural: (count: number, word: string) => string
  formatDate: (data?: Date) => string
  lang: string
  setLang: (lang: string) => void
  variables: any
}>({
  translate: () => '',
  translatePlural: () => '',
  formatDate: () => '',
  lang: 'en',
  setLang: () => '',
  variables: {}
})
I18nContext.displayName = 'I18nContext'

export const I18nProvider = ({ url, children }) => {
  const [i18n, setI18n] = useState<any>({})
  const [variables, setVariables] = useState<{}>({})
  const [loading, setLoading] = useState<boolean>(true)

  const setLang = (lang: string) => {
    const updateStates = data => {
      setI18n(data?.['i18n'])
      setVariables(data?.variables)
      setLoading(false)
    }

    const axiosInstance = authAxios()

    if (process.env.NODE_ENV === 'development' || !cache[lang]) {
      axiosInstance
        .get(url, { params: { lang } })
        .then(({ data }) => {
          updateStates(data)
          cache[lang] = data
        })
        .catch(errors => {
          console.error(errors)
          setLoading(false)
        })
    } else {
      updateStates(cache[lang])
      axiosInstance.get(url, { params: { lang } })
    }
  }

  useEffect(() => {
    setLang('')
  }, [])

  useEffect(() => {
    const handleKeyDown = event => {
      // We check whether Shift and Command (or Ctrl on Windows) are pressed simultaneously.
      if (event.shiftKey && (event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'l') {
        // Switching to the next language
        const allLanguages = Object.keys(i18n?.['header']?.['languages'])
        const currentLanguage = i18n?.['currentLanguage']
        const currentIndex = allLanguages.indexOf(currentLanguage)
        const nextIndex = (currentIndex + 1) % allLanguages.length
        setLang(allLanguages[nextIndex])
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [i18n])

  const translate = (path: string, replacements?: { [key: string]: string | Element }) => {
    let translation: any = { ...i18n }
    path.split('.').forEach(name => {
      translation = translation?.[name]
    })

    const elements = []

    if (!!replacements && !!translation) {
      translation = translation?.replace(/{\w[-\w]*}/g, p => {
        const value = replacements[p.substring(1, p.length - 1)]
        if (React.isValidElement(value)) {
          elements.push(value)
          return '{}'
        } else return value
      })
    }

    if (elements.length > 0) {
      const arr: string[] = translation.split('{}')
      translation = (
        <Fragment>
          {arr.map((str, index) => (
            <Fragment key={index}>
              {str}
              {elements[index]}
            </Fragment>
          ))}
        </Fragment>
      )
    }

    return translation || path
  }

  const translatePlural = (count: number, word: string) => {
    return selectPluralForm({
      language: i18n?.['currentLanguage'],
      count,
      word: i18n?.['plurality']?.[word]
    })
  }

  const formatDate = (date?: Date) => {
    if (!date) return '-'

    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    switch (translate('currentLanguage')) {
      case 'en':
        return `${translate(`months.${month}`)} ${day}, ${year}`
      case 'ru':
        return `${day} ${translate(`months.${month}`)} ${year} г.`
      default:
        return date?.toISOString()
    }
  }

  return (
    <I18nContext.Provider
      value={{ translate, translatePlural, formatDate, lang: i18n?.currentLanguage, setLang, variables }}
    >
      {loading ? <LoadingSpinner /> : children}
    </I18nContext.Provider>
  )
}
