import { DependencyList, useEffect, useState } from 'react'
import { AnyObject, AnySchema, Maybe, ValidateOptions } from 'yup'

export interface FormValidationProps<T> {
  initial?: T
  onErrorsChanged?: (errors: Record<string, string>) => void
  schema: AnySchema
  context?: Maybe<AnyObject>
}

export interface FormValidationOutput<T> {
  formData: T
  setFormData: (newFormData: T, validatePath?: string | string[]) => void
  validate: (onValid?: () => void, onInvalid?: () => void) => void
  validateAt: (path: string, onValid?: () => void) => void
  errors: Record<string, string>
  filterErrors: (name: string, startsWith?: boolean) => Record<string, string>
}

export const useFormValidation = <T>(
  { initial, onErrorsChanged = () => {}, schema, context }: FormValidationProps<T>,
  deps: DependencyList = []
): FormValidationOutput<T> => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [formData, _setFormData] = useState<T>(initial)

  useEffect(() => {
    console.debug('Validation errors', errors)
    onErrorsChanged(errors)
  }, [errors])

  useEffect(() => {
    const targetNode = document.getElementById('adv-page')

    const observer = new MutationObserver(mutationList => {
      const theFirstInvalidInput = mutationList?.find(
        mutation =>
          mutation?.attributeName === 'class' && (mutation?.target as HTMLElement)?.classList?.contains('is-invalid')
      )

      if (theFirstInvalidInput) {
        ;(theFirstInvalidInput.target as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    })

    if (targetNode) {
      observer.observe(targetNode, {
        attributes: true,
        childList: false,
        subtree: true
      })
    }

    return () => observer.disconnect()
  }, deps)

  const setFormData = (newFormData: T, validatePath?: string | string[]) => {
    // doesn't work with function with prevState
    _setFormData(newFormData)
    if (validatePath) {
      validateFormDataAt(validatePath, newFormData)
    }
  }

  const validate = (onValid: () => void = () => {}, onInvalid: () => void = () => {}) => {
    setErrors({})

    schema
      .validate(formData, {
        context: context,
        strict: true,
        abortEarly: false
      } as ValidateOptions)
      .then(() => {
        setErrors({})
        onValid()
      })
      .catch(validationErrors => {
        setErrors(validationErrors.inner?.reduce((a, v) => ({ ...a, [v.path]: v.message }), {}))
        onInvalid()
      })
  }

  const validateFormDataAt = (validatePath: string | string[], _formData: AnyObject, onValid?: () => void) => {
    let _errors = { ...errors }

    const _validateAt = (path: string) => {
      return schema
        .validateAt(path, _formData, {
          context: context,
          strict: true,
          abortEarly: false
        } as ValidateOptions)
        .then(() => {
          Object.keys(errors || {})
            .filter(key => path.startsWith(key) || key.startsWith(path))
            .forEach(key => {
              delete _errors[key]
            })
          if (!!onValid) {
            onValid()
          }
        })
        .catch(caughtErrors => {
          Object.keys(errors || {})
            .filter(key => path.startsWith(key) || key.startsWith(path))
            .forEach(key => {
              delete _errors[key]
            })
          _errors = {
            ..._errors,
            ...caughtErrors.inner?.reduce((a, v) => ({ ...a, [v.path]: v.message }), {})
          }
        })
    }

    if (typeof validatePath === 'string') {
      _validateAt(validatePath).finally(() => setErrors(_errors))
    } else if (Array.isArray(validatePath)) {
      Promise.allSettled(validatePath.map(path => _validateAt(path))).finally(() => setErrors(_errors))
    }
  }

  const validateAt = (path: string, onValid?: () => void) => validateFormDataAt(path, formData, onValid)

  const filterErrors = (name: string, startsWith = false) =>
    Object.fromEntries(
      Object.entries(errors || {}).filter(([key]) => (startsWith ? key.startsWith(name) : key === name))
    )

  return { formData, setFormData, validate, validateAt, errors, filterErrors }
}
