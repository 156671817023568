import { useContext, useEffect, useState } from 'react'
import { RestErrorHandlingContext } from '../../providers/RestErrorHandlingProvider'

export const useSubscription = () => {
  const { axios } = useContext(RestErrorHandlingContext)
  const [creationAllowed, setCreationAllowed] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionsAvailableForUpgrade, setSubscriptionsAvailableForUpgrade] = useState([])
  const [currentSubscription, setCurrentSubscription] = useState()

  useEffect(() => {
    axios.get('/api/subscription/all').then(({ data }) => setSubscriptions(data))
    refreshActive()
    refreshCreationAllowed()
  }, [])

  const refreshActive = (callback = () => {}) => {
    axios.get('/api/subscription/active').then(({ data }) => {
      setCurrentSubscription(data)
      callback()
    })
  }

  const refreshCreationAllowed = (callback = (creationAllowed: boolean) => {}) => {
    axios.get('/api/campaign/creationAllowed').then(({ data }) => {
      setCreationAllowed(data?.creationAllowed)
      callback(data?.creationAllowed)
    })

    axios.get('/api/subscription/availableForUpgrade').then(({ data }) => setSubscriptionsAvailableForUpgrade(data))
  }

  return {
    subscriptions,
    currentSubscription,
    subscriptionsAvailableForUpgrade,
    creationAllowed,
    refreshActive,
    refreshCreationAllowed
  }
}
