import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from './AuthProvider'
import { authAxios } from '../util/api'
import { UserProperty } from '../types/userProperty'
import { I18nContext } from './I18nProvider'
import { subscribe } from '../util/socketClient'

export const SystemMenuContext = createContext<{
  crmOrdersCount: number
}>({
  crmOrdersCount: 0
})
SystemMenuContext.displayName = 'SystemMenuContext'
export const SystemMenuProvider = ({ children }) => {
  const { user, webSocketConnected } = useContext(AuthContext)
  const { lang } = useContext(I18nContext)
  let location = useLocation()
  const [initialized, setInitialized] = useState(false)
  const [crmOrdersCount, setCrmOrdersCount] = useState(0)

  useEffect(() => {
    if (webSocketConnected && !!user.userId) {
      const unsubscribe = subscribe(
        `/user/${user.userId}/newCrmOrderCount`,
        ({ body }) => setCrmOrdersCount(JSON.parse(body)?.newCrmOrderCount),
        { id: `ordersCountSubscriptionForUser${user.userId}` }
      )

      return () => unsubscribe()
    }
  }, [webSocketConnected, user])

  useEffect(() => {
    if (user && user.userId) {
      try {
        // @ts-ignore
        __insp.push(['identify', user.name])

        authAxios()
          .get(`/api/userproperty/${UserProperty.EMAIL}`)
          .then(({ data }) => {
            // @ts-ignore
            window.Intercom('boot', {
              api_base: 'https://api-iam.intercom.io',
              app_id: 'omg1s7b6',
              hide_default_launcher: true,
              custom_launcher_selector: '#intercom-custom-launcher',
              name: user.name, // Full name
              email: data?.value, // Email address
              user_id: user.userId, // User id
              created_at: new Date(user?.createdOn).getTime() // Signup date as a Unix timestamp
            })

            // @ts-ignore
            __insp.push(['tagSession', { email: data?.value, userid: user.userId }])

            setInitialized(true)
          })
      } catch (e) {
        console.error(e)
      }
    }
  }, [user])

  const _updateIntercom = (params?: object) => {
    try {
      // @ts-ignore
      window.Intercom('update', params)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (initialized) _updateIntercom()
  }, [location])

  useEffect(() => {
    if (initialized) _updateIntercom({ language_override: lang })
  }, [lang])

  return <SystemMenuContext.Provider value={{ crmOrdersCount }}>{children}</SystemMenuContext.Provider>
}
