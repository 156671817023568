import { useContext, useEffect, useState } from 'react'
import { subscribe } from '../../util/socketClient'
import { AuthContext } from '../../providers/AuthProvider'

export const useBalances = () => {
  const {
    user: { userId },
    webSocketConnected
  } = useContext(AuthContext)
  const [balances, setBalances] = useState()

  useEffect(() => {
    if (webSocketConnected && !!userId) {
      const unsubscribe = subscribe(`/user/${userId}/balance`, ({ body }) => setBalances(JSON.parse(body)), {
        id: `balanceSubscriptionForUser${userId}`
      })

      return () => unsubscribe()
    }
  }, [webSocketConnected, userId])

  return { balances }
}
