import React, { createContext } from 'react'
import { useBalances } from '../hooks/webSockets/useBalances'
import { Balance } from '../types/balance'

export const BalanceContext = createContext<{
  balances: Balance[]
}>({
  balances: []
})
BalanceContext.displayName = 'BalanceContext'

export const BalanceProvider = ({ children }) => {
  const { balances } = useBalances()

  return <BalanceContext.Provider value={{ balances }}>{children}</BalanceContext.Provider>
}
