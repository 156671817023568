import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../providers/AuthProvider'
import { send, subscribe } from '../../util/socketClient'
import { RestErrorHandlingContext } from '../../providers/RestErrorHandlingProvider'

export const useNotifications = () => {
  const { axios } = useContext(RestErrorHandlingContext)
  const {
    user: { userId },
    webSocketConnected,
    socketIO
  } = useContext(AuthContext)
  const [notifications, setNotifications] = useState([])
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)

  useEffect(() => {
    if (socketIO) {
      socketIO.on('notifications', notification => {
        setNotifications(prevState => {
          return [...prevState, notification]
        })
      })

      return () => {
        socketIO.off('notifications')
      }
    }

    return undefined
  }, [socketIO])

  useEffect(() => {
    if (webSocketConnected && !!userId) {
      const unsubscribe = subscribe(
        `/user/${userId}/notification`,
        ({ body }) => {
          const notification = JSON.parse(body)
          setNotifications(prevState => {
            send('/adv/notification/markAsRead', {}, notification?.notificationId)
            return [...prevState, notification]
          })
        },
        { id: `notificationSubscriptionForUser${userId}` }
      )

      fetchUnreadNotifications()

      return () => unsubscribe()
    }
  }, [webSocketConnected, userId])

  const fetchUnreadNotifications = () => {
    axios
      .get('/api/notification', {
        params: {
          page: 0,
          size: 2147483647, // MAX JAVA INTEGER
          read: false
        }
      })
      .then(({ data }) => setUnreadNotificationsCount(data?.length))
  }

  const decrementUnreadUCount = () => {
    setUnreadNotificationsCount(prev => {
      return prev > 0 ? prev - 1 : 0
    })
  }

  return { notifications, setNotifications, unreadNotificationsCount, decrementUnreadUCount }
}
