import React, { HTMLAttributes, ReactNode, useContext, useState } from 'react'
import { FloatingLabel, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { I18nContext } from '../../providers/I18nProvider'
import { ReactComponent as InfoIcon } from 'bootstrap-icons/icons/info-circle.svg'
import { trimSpaces } from '../../util/trimSpaces'

export const validationTest = (name, namesToCompare = []) => {
  return (value, context) => {
    let result = ''

    const amountOfDuplicates = namesToCompare.filter(_name => {
      const _value1 = value?.trim()
      const _value2 = context.parent?.[_name]?.trim()
      return _value1?.localeCompare(_value2, undefined, { sensitivity: 'base' }) === 0
    })?.length

    if (amountOfDuplicates > 0) {
      result = 'unique'
    }

    return !!result ? context.createError({ path: name, message: result }) : true
  }
}

interface TextFormGroupProps {
  formId?: string
  name?: string
  as?: 'input' | 'textarea'
  min?: number
  max?: number
  type?: string
  hidden?: boolean
  required?: boolean
  readOnly?: boolean
  showText?: boolean
  showPlaceholder?: boolean
  autoFocus?: boolean
  withTrimSpaces?: boolean
  autoComplete?: string
  value?: string
  onChange?: (value: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  errors?: Record<string, string>
  hintEnabled?: boolean
  className?: string
  prefix?: {
    component: ReactNode
    props?: HTMLAttributes<HTMLElement>
  }
  placeholderParams?: Record<string, string | Element>
  inputClassName?: string
}

export const TextFormGroup: React.FC<TextFormGroupProps> = ({
  formId = '',
  name = '',
  as = 'input',
  min = 0,
  max = 100,
  type = 'text',
  hidden = false,
  required = false,
  readOnly = false,
  showText = true,
  showPlaceholder = false,
  autoFocus = false,
  withTrimSpaces = false,
  autoComplete = 'off',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  errors = {},
  hintEnabled = false,
  className = 'my-4',
  prefix,
  placeholderParams,
  inputClassName = ''
}) => {
  const { translate } = useContext(I18nContext)
  const [focused, setFocused] = useState(autoFocus)

  return (
    <div className={`overflow-hidden ${className}`}>
      <InputGroup>
        {prefix && (
          <InputGroup.Text role="button" {...prefix.props}>
            {prefix.component}
          </InputGroup.Text>
        )}
        <FloatingLabel
          controlId={`control_${name}`}
          hidden={hidden}
          label={
            <p>
              {translate(`forms.${formId}.${name}.label`)}
              {!!required && <i className="text-muted ms-1">{translate('required')}</i>}
            </p>
          }
        >
          <Form.Control
            className={inputClassName}
            as={as}
            name={name}
            type={type}
            value={value}
            disabled={readOnly}
            style={as === 'textarea' ? { height: '8rem' } : {}}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            isInvalid={Object.keys(errors).length > 0}
            placeholder={showPlaceholder ? translate(`forms.${formId}.${name}.placeholder`, placeholderParams) : ' '}
            onFocus={() => setFocused(true)}
            onBlur={event => {
              onBlur(event)
              setFocused(false)
              if (withTrimSpaces && value.trimEnd() !== value) {
                onChange(value.trimEnd())
              }
            }}
            onChange={event =>
              withTrimSpaces ? onChange(trimSpaces(event.target.value)) : onChange(event.target.value)
            }
          />
          <div className={'position-absolute adv-top-2 adv-end-2 d-flex'}>
            <small
              className={`${!max || !focused ? 'invisible' : ''} ${
                readOnly ? 'adv-bg-gray-200' : 'bg-white'
              } text-muted pt-1 px-${hintEnabled ? '1' : '2'}`}
            >
              {value?.length || 0}/{max}
            </small>
            {hintEnabled && (
              <small className={'d-flex align-items-center text-muted pt-1 px-1'}>
                <OverlayTrigger
                  overlay={<Tooltip>{translate(`forms.${formId}.${name}.hint`)}</Tooltip>}
                  trigger={['hover', 'click']}
                >
                  <InfoIcon />
                </OverlayTrigger>
              </small>
            )}
          </div>
        </FloatingLabel>
      </InputGroup>

      {Object.values(errors || {}).map(errorCode => (
        <Form.Control.Feedback key={errorCode} type="invalid" className="d-block">
          {translate(`forms.${formId}.${name}.${errorCode}`, { max, min })}
        </Form.Control.Feedback>
      ))}
      {showText && <Form.Text className="text-muted">{translate(`forms.${formId}.${name}.text`)}</Form.Text>}
    </div>
  )
}
